import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Card, CardContent, Grid, FormControlLabel, Checkbox } from '@mui/material';
import axios from 'axios';
import Menu from './Menu';
import TopBar from './TopBar';
import Footer from './Footer';
import './DarkWebMonitoring.css';
import { exportToCsv } from './exportToCsv';

const SNUSBASE_API_KEY = 'sb6ft38do0h57wlb95bxwrw3y0zy8y'; // Replace with your Snusbase API key

interface SnusbaseResult {
  took: number;
  size: number;
  results: Record<string, any[]>; // Define results as a dictionary with string keys and array of any values
}

const DarkWebMonitoring: React.FC = () => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('email'); // Default search type
  const [wildcard, setWildcard] = useState(false);
  const [snusbaseResult, setSnusbaseResult] = useState<SnusbaseResult | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSearchSnusbase = async () => {
    try {
      const response = await axios.post('https://api.snusbase.com/data/search', {
        terms: [query],
        types: [searchType],
        wildcard: wildcard
      }, {
        headers: {
          'Auth': SNUSBASE_API_KEY,
          'Content-Type': 'application/json'
        }
      });
      setSnusbaseResult(response.data);
    } catch (error) {
      console.error('Error fetching Snusbase data:', error);
    }
  };

  const handleExportToCsv = () => {
    if (!snusbaseResult || !snusbaseResult.results || !Object.keys(snusbaseResult.results).length) {
      console.error('No results to export');
      return;
    }
  
    const formattedResults: any[] = [];
    Object.keys(snusbaseResult.results).forEach(key => {
      const entries = snusbaseResult.results[key];
      entries.forEach(entry => {
        formattedResults.push({ source: key, ...entry });
      });
    });
  
    exportToCsv(formattedResults, 'darkweb_results.csv');
  };

  return (
    <Box className="dark-web-monitoring">
      <TopBar setDrawerOpen={setDrawerOpen} />
      <Menu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box className="content">
        <Typography variant="h4" gutterBottom>
          Dark Web Monitoring
        </Typography>
        <Box className="search-bar">
          <TextField
            label="Search now"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
            variant="outlined"
            className="search-input"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchSnusbase}
            className="search-button"
          >
            Search
          </Button>
        </Box>
        <Box className="search-options">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === 'email' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('email')}
                className="option-button"
              >
                Email
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === 'username' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('username')}
                className="option-button"
              >
                Username
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === 'lastip' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('lastip')}
                className="option-button"
              >
                IP Address
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === 'password' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('password')}
                className="option-button"
              >
                Password
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === 'hash' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('hash')}
                className="option-button"
              >
                Hash
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant={searchType === '_domain' ? 'contained' : 'outlined'}
                onClick={() => setSearchType('_domain')}
                className="option-button"
              >
                Domain
              </Button>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={wildcard}
                onChange={(e) => setWildcard(e.target.checked)}
                color="primary"
              />
            }
            label="Enable Wildcard"
            className="wildcard-checkbox"
          />
        </Box>
        {snusbaseResult && (
          <Card className="results-card" sx={{ marginTop: 4 }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: '#fff' }}>Results</Typography>
              <Box className="results-container" sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', color: '#fff' }}>
                  {JSON.stringify(snusbaseResult, null, 2)}
                </pre>
              </Box>
              <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExportToCsv}
                >
                  Export to CSV
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default DarkWebMonitoring;
