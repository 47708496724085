import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Button, Card, CardContent, Grid, MenuItem, Select, FormControl, InputLabel, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TableSortLabel
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import TopBar from './TopBar';
import Footer from './Footer';
import Menu from './Menu';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './ClientOutreach.css';

interface Outreach {
  id: string;
  clientName: string;
  date: Date;
  method: string;
  note: string;
}

type Order = 'asc' | 'desc';

const ClientOutreachPage: React.FC = () => {
  const [outreachData, setOutreachData] = useState<Outreach[]>([]);
  const [newOutreach, setNewOutreach] = useState({ clientName: '', date: '', method: '', note: '' });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Outreach>('clientName');

  useEffect(() => {
    const fetchData = async () => {
      const outreachCollection: Outreach[] = [];
      const querySnapshot = await getDocs(collection(db, 'client_outreach'));
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Outreach;
        outreachCollection.push({ ...data, id: doc.id });
      });
      setOutreachData(outreachCollection);
    };

    fetchData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewOutreach(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setNewOutreach(prevState => ({
      ...prevState,
      [name as string]: value as string,
    }));
  };

  const handleAddOutreach = async () => {
    if (newOutreach.clientName && newOutreach.date && newOutreach.method && newOutreach.note) {
      try {
        const outreachData = {
          clientName: newOutreach.clientName,
          date: new Date(newOutreach.date),
          method: newOutreach.method,
          note: newOutreach.note,
        };
        const docRef = await addDoc(collection(db, 'client_outreach'), outreachData);
        setOutreachData((prevOutreachData) => [
          ...prevOutreachData,
          { ...outreachData, id: docRef.id },
        ]);
        setNewOutreach({ clientName: '', date: '', method: '', note: '' });
      } catch (error) {
        console.error('Error Adding Outreach:', error);
      }
    }
  };

  const handleDeleteOutreach = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'client_outreach', id));
      setOutreachData(outreachData.filter(outreach => outreach.id !== id));
    } catch (error) {
      console.error('Error Deleting Outreach:', error);
    }
  };

  const handleRequestSort = (property: keyof Outreach) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = () => {
    return [...outreachData].sort((a, b) => {
      if (orderBy === 'date') {
        return (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1) * (order === 'asc' ? 1 : -1);
      } else {
        return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === 'asc' ? 1 : -1);
      }
    });
  };

  const aggregateOutreachData = () => {
    const aggregatedData: { [key: string]: { Phone: number, Text: number, Email: number } } = {};

    outreachData.forEach(outreach => {
      if (!aggregatedData[outreach.clientName]) {
        aggregatedData[outreach.clientName] = { Phone: 0, Text: 0, Email: 0 };
      }
      aggregatedData[outreach.clientName][outreach.method as keyof typeof aggregatedData['']] += 1;
    });

    return aggregatedData;
  };

  const aggregatedData = aggregateOutreachData();
  const clientNames = Object.keys(aggregatedData);

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    xaxis: {
      categories: clientNames,
    },
    colors: ['#FF4560', '#008FFB', '#00E396'],
    tooltip: {
      y: {
        formatter: function (val: number, opts: any) {
          return `${opts.w.globals.seriesNames[opts.seriesIndex]}: ${val}`;
        },
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'center'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number, opts: any) {
        return opts.w.globals.seriesNames[opts.seriesIndex];
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        vertical: 8,
      },
    },
  };

  const series = [
    {
      name: 'Phone',
      data: clientNames.map(name => aggregatedData[name].Phone),
    },
    {
      name: 'Text',
      data: clientNames.map(name => aggregatedData[name].Text),
    },
    {
      name: 'Email',
      data: clientNames.map(name => aggregatedData[name].Email),
    },
  ];

  return (
    <Box className="client-outreach-page" sx={{ bgcolor: 'black' }}>
      <TopBar setDrawerOpen={setDrawerOpen} />
      <Menu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box className="content">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card className="form-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Add Client Outreach
                </Typography>
                <TextField
                  label="Client Name"
                  name="clientName"
                  value={newOutreach.clientName}
                  onChange={handleInputChange}
                  fullWidth
                  className="textfield-input"
                />
                <TextField
                  label="Date"
                  name="date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={newOutreach.date}
                  onChange={handleInputChange}
                  fullWidth
                  className="textfield-input"
                />
                <FormControl fullWidth className="textfield-input">
                  <InputLabel>Method</InputLabel>
                  <Select
                    name="method"
                    value={newOutreach.method}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="Phone">Phone</MenuItem>
                    <MenuItem value="Text">Text</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Note"
                  name="note"
                  value={newOutreach.note}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  className="textfield-input"
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddOutreach}
                  className="add-outreach-button"
                >
                  Add Outreach
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="client-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Client Outreach Metrics
                </Typography>
                <Box className="client-table-wrapper">
                  <TableContainer className="client-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-cell">
                            <TableSortLabel
                              active={orderBy === 'clientName'}
                              direction={orderBy === 'clientName' ? order : 'asc'}
                              onClick={() => handleRequestSort('clientName')}
                            >
                              Client Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className="table-cell">
                            <TableSortLabel
                              active={orderBy === 'date'}
                              direction={orderBy === 'date' ? order : 'asc'}
                              onClick={() => handleRequestSort('date')}
                            >
                              Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className="table-cell">
                            <TableSortLabel
                              active={orderBy === 'method'}
                              direction={orderBy === 'method' ? order : 'asc'}
                              onClick={() => handleRequestSort('method')}
                            >
                              Method
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className="table-cell">Note</TableCell>
                          <TableCell className="table-cell">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData().map(outreach => (
                          <TableRow key={outreach.id}>
                            <TableCell className="table-cell">{outreach.clientName}</TableCell>
                            <TableCell className="table-cell">{new Date(outreach.date).toLocaleDateString()}</TableCell>
                            <TableCell className="table-cell">{outreach.method}</TableCell>
                            <TableCell className="table-cell">{outreach.note}</TableCell>
                            <TableCell className="table-cell">
                              <IconButton color="secondary" onClick={() => handleDeleteOutreach(outreach.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="chart-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Outreach Methods Overview
                </Typography>
                <Chart
                  options={chartOptions}
                  series={series}
                  type="bar"
                  height={350}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default ClientOutreachPage;
