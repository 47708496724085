import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Box
} from '@mui/material';
import {
  Close as CloseIcon,
  Folder as FolderIcon,
  Cloud as CloudIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  People as PeopleIcon,
  DarkMode as DarkModeIcon // Added icon for Dark Web Monitoring
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext'; // Corrected import path

interface MenuProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu: React.FC<MenuProps> = ({ drawerOpen, setDrawerOpen }) => {
  const navigate = useNavigate();
  const { logout } = useAuth(); // Destructure the logout function from useAuth

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function
      navigate('/'); // Navigate to the home or login page after logout
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
      >
        <IconButton onClick={() => setDrawerOpen(false)} className="close-button">
          <CloseIcon />
        </IconButton>
        <List>
          <ListItem button onClick={() => navigate('/dashboard')}>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/securefileupload')}>
            <ListItemIcon><CloudIcon /></ListItemIcon>
            <ListItemText primary="Secure File Upload" />
          </ListItem>
          <ListItem button onClick={() => navigate('/admin')}>
            <ListItemIcon><AdminPanelSettingsIcon /></ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          <ListItem button onClick={() => navigate('/clientoutreach')}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Client Outreach" />
          </ListItem>
          <ListItem button onClick={() => navigate('/darkwebmonitoring')}>
            <ListItemIcon><DarkModeIcon /></ListItemIcon>
            <ListItemText primary="Dark Web Monitoring" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={handleLogout}> {/* Update the onClick to call handleLogout */}
            <ListItemIcon><CloseIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Menu;
