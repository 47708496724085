import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import SecureFileUpload from './components/SecureFileUpload';
import AdminPage from './components/AdminPage';
import ClientOutreach from './components/ClientOutreach';
import DarkWebMonitoring from './components/DarkWebMonitoring'; // Import DarkWebMonitoring
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './components/NotFound';


const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/securefileupload" element={<PrivateRoute element={<SecureFileUpload />} />} />
          <Route path="/admin" element={<PrivateRoute element={<AdminPage />} />} />
          <Route path="/clientoutreach" element={<PrivateRoute element={<ClientOutreach />} />} />
          <Route path="/darkwebmonitoring" element={<PrivateRoute element={<DarkWebMonitoring />} />} /> // Add DarkWebMonitoring route
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
